<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    SUBJECTS &nbsp; <v-chip color="yellow">{{subjectCount}}</v-chip>
                </v-card-title>
                <v-card-subtitle>
                    Manage Subjects - Add/Update/Remove
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                        <v-col cols="12" v-if="newform">
                            <v-alert
                                type="warning"
                                dense
                                border="left"
                                text
                                color="black"
                                v-if="editBtn"
                                >
                                We strongly advise against removing subject from classes that already have a history of result entries with the subject. Doing so will automatically hide the subject and the subject scores for that class.
                            </v-alert>
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>                                        
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.subject"
                                                label="Subject Name"
                                                :rules="[v => !!v || 'Subject Name is required']"
                                                required
                                            ></v-text-field>
                                        </v-col>                                        
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.display_position"
                                                label="Subject Order / Position"
                                                :rules="subjectOrderRules"
                                                required
                                                type="number" onwheel="this.blur()"
                                                min="1"
                                                max="30"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.teacher_name"
                                                label="Subject Teacher Name"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col
                                        cols="12"
                                        >
                                            <v-select
                                                v-model="form.class_groups"
                                                outlined
                                                :items="classes"
                                                item-text="class"
                                                item-value="class_id"
                                                :rules="[v => !!v || 'Class/es is required']"
                                                label="Select Classes the subject belongs to"
                                                multiple
                                                required
                                            ></v-select>
                                        </v-col>                                        
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord" v-if="!editBtn"
                                        >
                                            Create
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecord" v-else
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Go Back
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <info-loader-2 v-if="fetchingContent"/>
                        <v-row class="mb-1 mt-2" v-if="newform == false && fetchingContent == false">
                            <v-col cols="12">
                                <v-alert
                                    type="info"
                                    dense
                                    border="left"
                                    text
                                    color="orange"
                                    >
                                    <marquee>
                                        <span v-for="(info,i) in infos" :key="i" class="black--text mr-5">
                                        {{info.content}} | &nbsp;
                                        </span>
                                    </marquee>
                                </v-alert>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" v-for="(record, i) in records" :key="i">
                                <v-card elevation="2" color="#F5F5F5">
                                    <v-card-title class="subtitle-2">
                                        {{record.subject}}
                                    </v-card-title>
                                    <v-card-subtitle class="text-lowercase"><span v-for="(a, i) in record.class" :key="i"><small>{{a.class}}</small>, </span></v-card-subtitle>
                                    <v-card-actions>
                                        <v-btn @click="editRecord(record)" fab light x-small
                                        ><v-icon dark> mdi-pencil </v-icon>
                                        </v-btn>
                                        <v-btn v-if="record.has_results == 0" @click="deleteRecord(record)" fab color="error" x-small
                                        ><v-icon dark> mdi-trash-can-outline </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-btn tile text color="success" @click="reset">
                                    Add New Subject
                                </v-btn>
                            </v-col>                                                                
                        </v-row>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import InfoLoader2 from '../../components/InfoLoader2.vue';

  export default {
    name: 'Subjects',
    components: {
        HeaderVue,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getClasses()
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        form:{},
        records:[],
        classes:[],
        valid: true,
        subjectOrderRules: [
            v => !!v || ' Subject Order is required'
        ],
        infos:[
            {content: 'You should check first that a subject name doesnt exist before creating another. To add subjects to classes, click the pencil icon and select all the classes offering the subject. This is to avoid subjects duplication'},
            {content: 'You need not create same subject for different classes. You can simple create the subject once and add select the classes the subject is relevant to'},
        ],
        fetchingContent:true
    }),
    computed:{
        subjectCount(){
            return this.records.length
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.editBtn = false
            this.form = {}
        },
        getClasses () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.classes =  response.data.data
                 if (this.classes.length == 0) {
                    this.$toast.info('You will need to set classes first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('classes')
                }           
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/subjects?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.records =  response.data.data            
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.fetchingContent = false;
            });
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/subjects?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        editRecord (record){
            this.newform = true
            this.form.id = record.subject_id
            this.form.teacher_name = record.teacher_name
            this.form.display_position = parseInt(record.display_position)
            this.form.subject = record.subject
            this.form.class_groups = record.class.map((a)=>{return Number(a.class_id)})
            this.editBtn = true
        },
        updateRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/subjects/'+this.form.id+'?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.editBtn = false             
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/subjects/'+record.subject_id+'?school_id='+this.school.school_id+'&confirm=DELETE',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        }
    },
  }
</script>
